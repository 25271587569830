import * as dompack from 'dompack';

import * as whintegration from '@mod-system/js/wh/integration';
import * as mso from '@mod-mso/webdesigns/mso/mso';

import '@mod-mso/webdesigns/mso/mso.scss';
import '@mod-mso_templates/webdesigns/penitus/penitus';
import './eissink.scss';

import './widgets/history/history';

dompack.onDomReady(() => {
  insertSocials();
  new mso.MSOSite();

  prepareAnimations();
  setTimeout(AOS.refreshHard, 100);
});

function prepareAnimations() {
  for (const item of dompack.qSA('.w-ctas, .w-textimage')) {
    if (item.classList.contains('w-textimage')) {
      const imageContainer = item.querySelector('.w-textimage__imagecontainer');
      addAnimationTo(imageContainer, 'fade-down');
    } else {
      const ctas = Array.from(item.querySelectorAll('.w-ctas__col'));
      for (const [idx, el] of ctas.entries()) {
        addAnimationTo(el, 'fade-down', 200 * (idx % 3));
      }
    }
  }
}

function addAnimationTo(element, animation, delay = 0) {
  element.dataset.aos = animation;
  element.dataset.aosDuration = 800;

  if (delay !== 0) {
    element.dataset.aosDelay = delay;
  }
}

function insertSocials() {
  const header = dompack.qS('#ww-main-menu > .ww-header__desktopmenu');
  header.insertAdjacentHTML(
    'beforeend',
    whintegration.config.obj.rendersocials
  );
}
